import React, {useEffect, useMemo, useState} from 'react';
import {COLORS, SIZES} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {changeNavBar, updateHistoryMap} from 'app/actions/navbar';
import {useDispatch, useSelector} from 'react-redux';
import {NAVBAR_HOME, NAVBAR_MATCHES, NAVBAR_COMPETITIONS, NAVBAR_SEARCH} from 'app/reducers/navbar';
import {LinkOnComponent} from 'app/components/links/link';
import {createSelector} from 'reselect';
import {FuPaPlayer} from 'app/components/icons';
import {EmojiEvents, Event, Home, Search} from '@mui/icons-material';
import {useHistory} from 'react-router';
import {useEffectAfterMount} from 'app/hooks/dataHooks';
import {Badge} from '@mui/material';
import {styled as muiStyled} from '@mui/material/styles';
import {api} from 'app/services/api';
import LocalStorageManager from 'app/services/web-storage/LocalStorageManager';
import {reduxSelectDistrict} from 'app/hooks/reduxSelectDistrict';
import NavBarItem from 'app/components/navBar/NavBarItem';
import {useLocalStorage} from 'app/hooks/useLocalStorage';

const StyledBadge = muiStyled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: '-10px',
    border: `1px solid ${COLORS.lightestGrey}`,
    padding: '0 4px',
  },
}));

const Container = styled.nav`
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: ${props => props.theme.zIndexNavigation};
  display: flex;
  justify-content: center;
  min-height: ${SIZES['64']};
  overflow: hidden;
  bottom: 0;
  width: 100vw;
  background-color: ${COLORS.lightestGrey};
  box-shadow: 0 -1px 3px 0 ${COLORS.grey};
  padding: ${SIZES['10']} 0;
  padding-bottom: calc(${SIZES['10']} + env(safe-area-inset-bottom));

  ${props => props.theme.desktopLayout`
    all: initial;
    display: flex;
    justify-content: center;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  max-width: ${props => props.theme.desktopContentWidth}px;
`;

const StyledFuPaPlayer = styled(FuPaPlayer)`
  &&& {
    color: ${props => (props.selected ? COLORS.fupaSecondary : COLORS.darkerGrey)};
    font-size: 20;
    padding-left: ${SIZES[2]};
    ${props => props.theme.desktopLayout`
      display: none;
    `}
    path:last-child {
      fill: ${props => (props.selected ? COLORS.white : COLORS.darkerGrey)};
    }
  }
`;

const StyledHome = styled(Home)`
  &&& {
    color: ${props => (props.selected ? COLORS.fupaSecondary : COLORS.lighterGrey)};
    font-size: 20;
    display: none;
    ${props => props.theme.desktopLayout`
      display: flex;
      padding-bottom: 0.75rem;
    `}
  }
`;

const StyledEvent = styled(Event)`
  &&& {
    color: ${props => (props.selected ? COLORS.fupaSecondary : COLORS.darkerGrey)};
    font-size: 20;
    ${props => props.theme.desktopLayout`
      color: ${props.selected ? COLORS.fupaSecondary : COLORS.lighterGrey};
      padding-bottom: 0.75rem;
    `}
  }
`;

const StyledEmojiEvents = styled(EmojiEvents)`
  &&& {
    color: ${props => (props.selected ? COLORS.fupaSecondary : COLORS.darkerGrey)};
    font-size: 20;
    ${props => props.theme.desktopLayout`
    fill: ${props.selected ? COLORS.fupaSecondary : COLORS.lighterGrey};
    padding-bottom: 0.75rem;
    `}
  }
`;

const StyledSearch = styled(Search)`
  &&& {
    color: ${props => (props.selected ? COLORS.fupaSecondary : COLORS.darkerGrey)};
    font-size: 20;
    ${props => props.theme.desktopLayout`
    fill: ${props.selected ? COLORS.fupaSecondary : COLORS.lighterGrey};
    padding-bottom: 0.75rem;
    `}
  }
`;

const Line = styled.div`
  display: none;
  ${props => props.theme.desktopLayout`
    display: block;
    box-sizing: border-box;
    height: 2.5rem;
    border: 1px solid ${COLORS.fupaPrimaryFocused};
    margin-left: 0.625rem;
  `}
`;

const selectNavBar = createSelector(
  state => state.navBar,
  navBar => {
    return {
      activeTab: navBar.activeTab,
      defaultUrl: navBar.defaultUrl,
      historyMap: navBar.historyMap,
    };
  }
);

const NavBar = ({className}) => {
  const dispatch = useDispatch();
  const district = useSelector(reduxSelectDistrict);
  const {activeTab, defaultUrl, historyMap} = useSelector(selectNavBar);
  const history = useHistory();
  const [showLiveBadge, setShowLiveBadge] = useState(false);
  const isRegion = /^\/(test_)?region\//.test(history.location.pathname);
  // When fetching server-rendered pages like news from the s3 cache,
  // the links in the navbar are already set with a region (mostly the region of the author).
  // While hydrating the user could click on the navbar and navigate to a region he does not expect.
  // To prevent this, we use onClick handlers instead of links on urls other than /region/*
  const tabComponent = isRegion ? LinkOnComponent : 'div';

  const change = tabIndex => {
    dispatch(changeNavBar(tabIndex));
    if (!isRegion) {
      history.push(defaultUrl[tabIndex]);
    }
  };

  useEffect(() => {
    if (!district.slug) {
      return;
    }

    api
      .get(`/districts/${district.slug}/live-status`)
      .then(response => {
        setShowLiveBadge(!!response.data?.live);
      })
      .catch(() => {
        setShowLiveBadge(false);
      });
  }, [district.slug]);

  // Set active tab on back-navigation
  useEffectAfterMount(() => {
    const {action, location} = history;
    if (action === 'PUSH' || action === 'REPLACE') {
      dispatch(updateHistoryMap({key: location.key, tabIndex: activeTab}));
    } else {
      dispatch(changeNavBar(historyMap[location.key]));
    }
  }, [history.location.pathname, activeTab]);

  const navItems = useMemo(
    () => [
      {
        id: NAVBAR_HOME,
        label: 'Home',
        follow: !LocalStorageManager.getInstance().getItem('newDistrictPage'),
        children: [
          <StyledFuPaPlayer selected={activeTab === NAVBAR_HOME} />,
          <StyledHome selected={activeTab === NAVBAR_HOME} />,
        ],
      },
      {
        id: NAVBAR_MATCHES,
        label: 'Spiele',
        follow: true,
        children: [
          <StyledBadge badgeContent={'live'} color='secondary' invisible={!showLiveBadge}>
            <StyledEvent selected={activeTab === NAVBAR_MATCHES} />
          </StyledBadge>,
        ],
      },
      {
        id: NAVBAR_COMPETITIONS,
        label: 'Ligen',
        follow: true,
        children: [<StyledEmojiEvents selected={activeTab === NAVBAR_COMPETITIONS} />],
      },
      {
        id: NAVBAR_SEARCH,
        label: 'Suche',
        follow: true,
        children: [<StyledSearch selected={activeTab === NAVBAR_SEARCH} />],
      },
    ],
    [activeTab, showLiveBadge]
  );

  return (
    <Container className={className}>
      <Wrapper>
        {navItems.map(({id, label, children, follow}) => (
          <NavBarItem
            key={id}
            isSelected={activeTab === id}
            link={defaultUrl[id]}
            follow={follow}
            onClick={() => change(id)}
            componentType={tabComponent}
            label={label}>
            {children}
          </NavBarItem>
        ))}
      </Wrapper>
      <Line />
    </Container>
  );
};

export {NavBar};
